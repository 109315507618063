import React, { useState } from "react";
import { checkTokenValidity } from "../../../../services/api";
import { formValidate } from "../../../../utils/utils";
import * as S from "./styles";

const ModalRegisterCleaning = (props) => {
    //console.log(props.FiltersApi);

    //Ativa ou deasativa o loading
    const [Loading, setLoading] = useState(false);

    //variavel para lidar com as mensagens de erro ou sucesso
    const [formValidateStatus, setFormValidateStatus] = useState({
        type: "",
        message: "",
    });

    //Lidando com o submit do formulário
    const handleSumit = async (e) => {
        //Checando a validade do token
        if (checkTokenValidity()) {
            // Recarregar a página
            window.location.reload();
        }

        //Removendo o alerta caso ele exista
        props.setFiltersApi((Filters) => ({
            ...Filters,
            statusMsg: false,
        }));

        //Validando o formulário local
        if (!formValidate(props.FiltersApi, setFormValidateStatus)) {
            //Voltar ai topo da página
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

            setTimeout(() => {
                //Removendo o alerta caso ele exista
                props.setFiltersApi((Filters) => ({
                    ...Filters,
                    statusMsg: true,
                }));
            }, 300);

            return;
        } else {
            setLoading(true);

            //Voltar ai topo da página
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

            //Criando o objeto final antes de mandar para API

            //Enviando o formulário para API
            setTimeout(() => {
                //Savar informações
                const status = 200;
                //if (response.status === 200) {
                if (status === 200) {
                    if (props.FiltersApi.action === "create") {
                        props.setFiltersApi((Filters) => ({
                            ...Filters,
                            statusMsg: true,
                            tagEdit: "",
                            data: "",
                            hora: "",
                            tempAtual: "",
                            tempMin: "",
                            tempMax: "",
                            pressao: "",
                            urAtual: "",
                            urMinima: "",
                            urMaxima: "",
                            log: "",
                            responsavel: "",
                            concat: "",
                            teste1: "",
                            teste2: "",
                            teste3: "",
                        }));
                    }

                    if (props.FiltersApi.action === "edit") {
                        props.setFiltersApi((Filters) => ({
                            ...Filters,
                            statusMsg: true,
                        }));
                    }

                    setFormValidateStatus({
                        type: "success",
                        message: (
                            <>
                                <i className="bi bi-check-lg me-1"></i> Registro
                                realizado com <b>sucesso</b>.
                            </>
                        ),
                    });

                    setLoading(false);
                } else {
                    props.setFiltersApi((Filters) => ({
                        ...Filters,
                        statusMsg: true,
                    }));

                    setFormValidateStatus({
                        type: "error",
                        message: (
                            <>
                                <i className="bi bi-exclamation-triangle-fill me-1"></i>{" "}
                                Ocorreu um erro durante o registro.
                                <div className="mt-2">
                                    <b>Error:</b> {Error}
                                </div>
                            </>
                        ),
                    });

                    setLoading(false);
                }
            }, 1000);
        }
    };

    return (
        <S.ModalRegisterCleaning
            className="modal fade"
            id={props.FiltersApi.modal}
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-fullscreen-md-down modal-cq">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                            <i className={props.FiltersApi.icone}></i>{" "}
                            {props.FiltersApi.pagina}
                        </h1>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        {props.FiltersApi.statusMsg === true &&
                            formValidateStatus.type === "error" && (
                                <div
                                    className="alert alert-danger sticky-top"
                                    role="alert"
                                >
                                    {formValidateStatus.message}
                                </div>
                            )}

                        {props.FiltersApi.statusMsg === true &&
                            formValidateStatus.type === "success" && (
                                <div
                                    className="alert alert-success sticky-top"
                                    role="alert"
                                >
                                    {formValidateStatus.message}
                                </div>
                            )}

                        <div className="form-floating mb-3">
                            <input
                                id={
                                    props.FiltersApi &&
                                    props.FiltersApi.action === "create"
                                        ? props.FiltersApi.tag
                                        : props.FiltersApi &&
                                          props.FiltersApi.action === "edit"
                                        ? props.FiltersApi.tagEdit
                                        : ""
                                }
                                className="form-control"
                                type="text"
                                value={
                                    props.FiltersApi &&
                                    props.FiltersApi.action === "create"
                                        ? props.FiltersApi.tag
                                        : props.FiltersApi &&
                                          props.FiltersApi.action === "edit"
                                        ? props.FiltersApi.tagEdit
                                        : ""
                                }
                                disabled
                            />

                            <label
                                htmlFor={
                                    props.FiltersApi &&
                                    props.FiltersApi.action === "create"
                                        ? props.FiltersApi.tag
                                        : props.FiltersApi &&
                                          props.FiltersApi.action === "edit"
                                        ? props.FiltersApi.tagEdit
                                        : ""
                                }
                            >
                                Tag
                            </label>
                        </div>

                        <div className="form-floating mb-3">
                            <input
                                id={props.FiltersApi.data}
                                className="form-control mb-3"
                                type="date"
                                placeholder="name@example.com"
                                value={props.FiltersApi.data}
                                onChange={(e) =>
                                    props.setFiltersApi((Filters) => ({
                                        ...Filters,
                                        data: e.target.value,
                                    }))
                                }
                            />

                            <label htmlFor={props.FiltersApi.data}>Data</label>
                        </div>

                        <div className="form-floating mb-3">
                            <input
                                id={props.FiltersApi.hora}
                                className="form-control mb-3"
                                type="time"
                                placeholder="name@example.com"
                                value={props.FiltersApi.hora}
                                onChange={(e) =>
                                    props.setFiltersApi((Filters) => ({
                                        ...Filters,
                                        hora: e.target.value,
                                    }))
                                }
                            />

                            <label htmlFor={props.FiltersApi.hora}>Hora</label>
                        </div>

                        <div className="form-floating mb-3">
                            <input
                                id={props.FiltersApi.responsavel}
                                className="form-control mb-3"
                                type="text"
                                placeholder="name@example.com"
                                value={props.FiltersApi.responsavel}
                                onChange={(e) =>
                                    props.setFiltersApi((Filters) => ({
                                        ...Filters,
                                        responsavel: e.target.value,
                                    }))
                                }
                            />

                            <label htmlFor={props.FiltersApi.responsavel}>
                                Responsável
                            </label>
                        </div>

                        <div className="form-floating mb-3">
                            <input
                                id={props.FiltersApi.concat}
                                className="form-control mb-3"
                                type="text"
                                placeholder="name@example.com"
                                value={props.FiltersApi.concat}
                                onChange={(e) =>
                                    props.setFiltersApi((Filters) => ({
                                        ...Filters,
                                        concat: e.target.value,
                                    }))
                                }
                            />

                            <label htmlFor={props.FiltersApi.concat}>
                                Concat
                            </label>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => {
                                props.setFiltersApi((Filters) => ({
                                    ...Filters,
                                    statusMsg: true,
                                }));

                                handleSumit();
                            }}
                            disabled={Loading === true ? "disabled" : ""}
                        >
                            {Loading === true && (
                                <div
                                    className="spinner-border text-light div-btn-loading me-2"
                                    role="status"
                                >
                                    <span className="visually-hidden">
                                        Loading...
                                    </span>
                                </div>
                            )}
                            Gravar
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                        >
                            Cancelar
                        </button>
                    </div>
                </div>
            </div>
        </S.ModalRegisterCleaning>
    );
};

export default ModalRegisterCleaning;
