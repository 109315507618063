import styled from "styled-components";

export const TitlesCq = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    h4,
    h4 i {
        color: #013b82;
        font-size: 1.2rem;
        text-transform: uppercase;
    }

    a {
        min-width: 100px;
    }

    a,
    i {
        font-size: 1.1rem;
    }

    @media screen and (min-width: 768px) {
        h4,
        h4 i {
            font-size: 1.5rem;
        }

        a,
        i {
            font-size: 1.2rem;
        }
    }
`;
