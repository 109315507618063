import React, { useState, useEffect } from "react";
import Headder from "../../components/Headder";
import SideBar from "../../components/TableCq/SideBarCq";
import Titles from "../../components/TableCq/TitlesCq";
import Table from "../../components/TableCq/Table";
import FiltersCq from "../../components/TableCq/FiltersCq";
import { checkTokenValidity } from "../../services/api";
import QrCodeScanner from "../../components/TableCq/QrCodeScanner";
import Loading from "../../components/Loading";
import * as S from "./styles";

const CqEnvironmentalPage = () => {
    //Checando a validade do token
    if (checkTokenValidity()) {
        // Recarregar a página
        window.location.reload();
    }

    const [Environmental, setEnvironmental] = useState([]);

    //Filtro escolhido pelo usuário para buscar na API
    const [FiltersApi, setFiltersApi] = useState({
        tag: "",
        dataInicio: "",
        dataFinal: "",
        pagina: "Ambiental",
        icone: "bi bi-tree-fill",
        modal: "modalRegisterEnvironmental",
        action: "",
        statusMsg: "",
        salaEdit: "",
        data: "",
        hora: "",
        teste1: "",
        teste2: "",
        teste3: "",
        concat: "",
    });

    //Ativa ou deasativa o loading
    const [loading, setLoading] = useState(true);

    //Variáveis de erros
    const [Error, setError] = useState("");
    const [StatusError, setStatusError] = useState(false);
    const [StatusGetTags, setStatusGetTags] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            (async () => {
                //const response = await getDoctors(User.codClinic);

                const response = [
                    {
                        tag: "IRERE-SA01",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "APROVADO",
                        teste2: "APROVADO",
                        teste3: "APROVADO",
                        concat: "IRERE-SA01455361",
                    },
                    {
                        tag: "IRERE-SA02",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "APROVADO",
                        teste2: "REPROVADO",
                        teste3: "REPROVADO",
                        concat: "IRERE-SA02455361",
                    },
                    {
                        tag: "IRERE-SA03",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "REPROVADO",
                        teste2: "REPROVADO",
                        teste3: "APROVADO",
                        concat: "IRERE-SA03455361",
                    },
                    {
                        tag: "IRERE-SA04",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "APROVADO",
                        teste2: "APROVADO",
                        teste3: "APROVADO",
                        concat: "IRERE-SA04455361",
                    },
                    {
                        tag: "IRERE-SA05",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "APROVADO",
                        teste2: "APROVADO",
                        teste3: "REPROVADO",
                        concat: "IRERE-SA05455361",
                    },
                    {
                        tag: "IRERE-SA06",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "APROVADO",
                        teste2: "APROVADO",
                        teste3: "APROVADO",
                        concat: "IRERE-SA06455361",
                    },
                    {
                        tag: "IRERE-SA07",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "REPROVADO",
                        teste2: "APROVADO",
                        teste3: "REPROVADO",
                        concat: "IRERE-SA07455361",
                    },
                    {
                        tag: "IRERE-SA08",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "APROVADO",
                        teste2: "APROVADO",
                        teste3: "REPROVADO",
                        concat: "IRERE-SA08455361",
                    },
                    {
                        tag: "IRERE-SA09",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "REPROVADO",
                        teste2: "REPROVADO",
                        teste3: "APROVADO",
                        concat: "IRERE-SA09455361",
                    },
                    {
                        tag: "IRERE-SA10",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "APROVADO",
                        teste2: "APROVADO",
                        teste3: "APROVADO",
                        concat: "IRERE-SA10455361",
                    },
                    {
                        tag: "BRIGADEIRO-SA01",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "REPROVADO",
                        teste2: "REPROVADO",
                        teste3: "APROVADO",
                        concat: "BRIGADEIRO-SA01455361",
                    },
                    {
                        tag: "BRIGADEIRO-SA02",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "APROVADO",
                        teste2: "REPROVADO",
                        teste3: "APROVADO",
                        concat: "BRIGADEIRO-SA02455361",
                    },
                    {
                        tag: "BRIGADEIRO-SA03",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "REPROVADO",
                        teste2: "REPROVADO",
                        teste3: "REPROVADO",
                        concat: "BRIGADEIRO-SA03455361",
                    },
                    {
                        tag: "BRIGADEIRO-SA04",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "APROVADO",
                        teste2: "APROVADO",
                        teste3: "REPROVADO",
                        concat: "BRIGADEIRO-SA04455361",
                    },
                    {
                        tag: "BRIGADEIRO-SA05",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "APROVADO",
                        teste2: "APROVADO",
                        teste3: "APROVADO",
                        concat: "BRIGADEIRO-SA05455361",
                    },
                    {
                        tag: "BRIGADEIRO-SA06",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "APROVADO",
                        teste2: "APROVADO",
                        teste3: "REPROVADO",
                        concat: "BRIGADEIRO-SA06455361",
                    },
                    {
                        tag: "BRIGADEIRO-SA07",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "REPROVADO",
                        teste2: "REPROVADO",
                        teste3: "APROVADO",
                        concat: "BRIGADEIRO-SA07455361",
                    },
                    {
                        tag: "BRIGADEIRO-SA08",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "APROVADO",
                        teste2: "APROVADO",
                        teste3: "REPROVADO",
                        concat: "BRIGADEIRO-SA08455361",
                    },
                    {
                        tag: "BRIGADEIRO-SA09",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "APROVADO",
                        teste2: "APROVADO",
                        teste3: "APROVADO",
                        concat: "BRIGADEIRO-SA09455361",
                    },
                    {
                        tag: "BRIGADEIRO-SA10",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "REPROVADO",
                        teste2: "REPROVADO",
                        teste3: "APROVADO",
                        concat: "BRIGADEIRO-SA10455361",
                    },
                    {
                        tag: "JABAQUARA-SA01",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "APROVADO",
                        teste2: "REPROVADO",
                        teste3: "APROVADO",
                        concat: "JABAQUARA-SA01455361",
                    },
                    {
                        tag: "JABAQUARA-SA02",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "REPROVADO",
                        teste2: "REPROVADO",
                        teste3: "REPROVADO",
                        concat: "JABAQUARA-SA02455361",
                    },
                    {
                        tag: "JABAQUARA-SA03",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "REPROVADO",
                        teste2: "APROVADO",
                        teste3: "REPROVADO",
                        concat: "JABAQUARA-SA03455361",
                    },
                    {
                        tag: "JABAQUARA-SA04",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "APROVADO",
                        teste2: "REPROVADO",
                        teste3: "REPROVADO",
                        concat: "JABAQUARA-SA04455361",
                    },
                    {
                        tag: "JABAQUARA-SA05",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "APROVADO",
                        teste2: "APROVADO",
                        teste3: "APROVADO",
                        concat: "JABAQUARA-SA05455361",
                    },
                    {
                        tag: "JABAQUARA-SA06",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "REPROVADO",
                        teste2: "REPROVADO",
                        teste3: "APROVADO",
                        concat: "JABAQUARA-SA06455361",
                    },
                    {
                        tag: "JABAQUARA-SA07",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "REPROVADO",
                        teste2: "REPROVADO",
                        teste3: "REPROVADO",
                        concat: "JABAQUARA-SA07455361",
                    },
                    {
                        tag: "JABAQUARA-SA08",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "APROVADO",
                        teste2: "APROVADO",
                        teste3: "APROVADO",
                        concat: "JABAQUARA-SA08455361",
                    },
                    {
                        tag: "JABAQUARA-SA09",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "APROVADO",
                        teste2: "REPROVADO",
                        teste3: "REPROVADO",
                        concat: "JABAQUARA-SA09455361",
                    },
                    {
                        tag: "JABAQUARA-SA10",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "APROVADO",
                        teste2: "REPROVADO",
                        teste3: "REPROVADO",
                        concat: "JABAQUARA-SA10455361",
                    },
                    {
                        tag: "IRERE-SA01",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "APROVADO",
                        teste2: "APROVADO",
                        teste3: "APROVADO",
                        concat: "IRERE-SA01455362",
                    },
                    {
                        tag: "IRERE-SA02",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "REPROVADO",
                        teste2: "APROVADO",
                        teste3: "REPROVADO",
                        concat: "IRERE-SA02455362",
                    },
                    {
                        tag: "IRERE-SA03",
                        data: "2024-09-01",
                        hora: "09:00",
                        teste1: "APROVADO",
                        teste2: "REPROVADO",
                        teste3: "REPROVADO",
                        concat: "IRERE-SA03455362",
                    },
                ];

                const status = 200;

                //if (response.status === 200) {
                if (status === 200) {
                    //setEnvironmental(response.data.medicos);
                    setEnvironmental(response);
                    setStatusGetTags(false);
                    setLoading(false);
                } else {
                    if (!response.data) {
                        setError(response.message);
                        //console.log(response);
                    }
                    setStatusError(true);
                    setStatusGetTags(false);
                    setLoading(false);
                }
            })();
        }, "1000");
    }, [StatusGetTags]);

    return (
        <S.CqEnvironmentalPage>
            {loading !== false && <Loading className="loading" />}
            <Headder />
            <SideBar page="cqEnvironmental" />

            <div className="main-cq-environmental div-environmental">
                <div className="container-fluid">
                    <Titles title="Ambiental" icon="bi bi-tree-fill" />

                    <QrCodeScanner
                        setFiltersApi={setFiltersApi}
                        FiltersApi={FiltersApi}
                    />

                    <FiltersCq
                        setFiltersApi={setFiltersApi}
                        FiltersApi={FiltersApi}
                        setStatusGetTags={setStatusGetTags}
                        setLoading={setLoading}
                    />

                    {StatusError && (
                        <div
                            className="alert alert-danger mt-3 mb-4 sticky-top"
                            role="alert"
                        >
                            <i className="bi bi-exclamation-triangle-fill me-1"></i>{" "}
                            Erro ao carregar a página! Se o problema persistir
                            entre em contato com <strong>Administrador</strong>.
                            <div className="mt-2">
                                <b>Error:</b> {Error}
                            </div>
                        </div>
                    )}

                    {StatusError === false && loading === false && (
                        <Table
                            data={Environmental}
                            tableName="cqEnvironmental"
                            setFiltersApi={setFiltersApi}
                            FiltersApi={FiltersApi}
                        />
                    )}
                </div>
            </div>
        </S.CqEnvironmentalPage>
    );
};

export default CqEnvironmentalPage;
