import styled from "styled-components";

export const HomePage = styled.div`
    padding-top: 100px;

    .div-cards-alert {
        margin-bottom: 40px;
    }

    .div-cards {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }

    .div-cards .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 2px solid #00b4e5;
        border-radius: 7px;
        margin-right: 10px;
        margin-left: 10px;
        margin-bottom: 20px;
        padding: 15px;
        width: 190px;
        height: 155px;
        text-decoration: none;
        color: #00b4e5;
    }

    .div-cards .card:hover {
        color: #fff;
        background-color: #00b4e5;
        transition: 0.3s;
    }

    .div-cards .card i {
        font-size: 55px;
    }

    .div-cards .card h2 {
        font-size: 1.5rem;
    }

    @media screen and (max-width: 600px) {
        .div-cards .card {
            width: 120px;
            height: 100px;
        }

        .div-cards .card h2 {
            font-size: 1rem;
        }

        .div-cards .card i {
            font-size: 30px;
        }
    }
`;
