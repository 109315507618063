import styled from "styled-components";

export const QrCodeScanner = styled.div`
    .btn-scan {
        background-color: #013b82;
        border: 1px solid #013b82;
    }

    .btn-scan:hover {
        background-color: #0049a2;
    }

    .div-qrcode-icon i {
        font-size: 3rem;
    }

    @media only screen and (min-width: 1024px) {
        display: none;
    }
`;
