import React, { useMemo } from "react";
import {
    useTable,
    useSortBy,
    useGlobalFilter,
    useFilters,
    usePagination,
    useColumnOrder,
} from "react-table";
import TablePagination from "../PaginationTable";
import ColumnOptions from "../ColumnOptions";
import ModalRegisterTemp from "../../TableCq/ModalsCq/ModalRegisterTemp";
import ModalRegisterCleaning from "../../TableCq/ModalsCq/ModalRegisterCleaning";
import ModalRegisterEnvironmental from "../../TableCq/ModalsCq/ModalRegisterEnvironmental";
import { TempColumns } from "../ColumnsCq/TempColumns";
import { CleaningColumns } from "../ColumnsCq/CleaningColumns";
import { EnvironmentalColumns } from "../ColumnsCq/EnvironmentalColumns";
import * as XLSX from "xlsx";
import * as S from "./styles";

const GlobalTable = (props) => {
    let COLUMNS = "";

    if (props.tableName === "cqTemp") {
        COLUMNS = TempColumns;
    }

    if (props.tableName === "cqCleaning") {
        COLUMNS = CleaningColumns;
    }

    if (props.tableName === "cqEnvironmental") {
        COLUMNS = EnvironmentalColumns;
    }

    const columns = useMemo(() => COLUMNS, [COLUMNS]);

    const data = useMemo(() => props.data, [props.data]);

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: {
                hiddenColumns: columns.map((column) => {
                    if (column.show === false) {
                        return column.accessor || column.id;
                    }
                    return null;
                }),
                pageSize: 10,
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useColumnOrder
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
        state,
        setGlobalFilter,
    } = tableInstance;

    const { pageIndex, pageSize } = state;

    const { globalFilter } = state;

    const downloadExcel = () => {
        const newData = data.map((row) => {
            delete row.tableData;
            return row;
        });
        const workSheet = XLSX.utils.json_to_sheet(newData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "students");
        //Buffer
        //let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(workBook, props.tableName + ".xlsx");
    };

    return (
        <S.GlobalTable>
            {data !== "" && (
                <div className="div-main-table">
                    <ColumnOptions
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        FiltersApi={props.FiltersApi}
                        setFiltersApi={props.setFiltersApi}
                    />
                    <div className="table-responsive div-table">
                        <table
                            className="table table-hover"
                            {...getTableProps()}
                        >
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th
                                                key={column.id}
                                                className={
                                                    column.id === "tag"
                                                        ? "th-tags"
                                                        : column.id === "log"
                                                        ? "th-log"
                                                        : column.id ===
                                                              "detalhes" ||
                                                          column.id === "editar"
                                                        ? "text-center"
                                                        : ""
                                                }
                                            >
                                                <span className="thead-div-arrow">
                                                    <span>
                                                        {column.render(
                                                            "Header"
                                                        )}
                                                    </span>

                                                    <span
                                                        {...column.getHeaderProps(
                                                            column.getSortByToggleProps()
                                                        )}
                                                    >
                                                        {!column.disableSortBy ? (
                                                            <span>
                                                                {column.isSorted ? (
                                                                    column.isSortedDesc ? (
                                                                        <i className="bi bi-chevron-down ms-3 text-black" />
                                                                    ) : (
                                                                        <i className="bi bi-chevron-up ms-3 text-black" />
                                                                    )
                                                                ) : (
                                                                    <div className="div-thead-arrow-up-down text-black">
                                                                        <i className="bi bi-chevron-up ms-3" />
                                                                        <i className="bi bi-chevron-down ms-3" />
                                                                    </div>
                                                                )}
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </span>
                                                </span>
                                            </th>
                                        ))}
                                        <th></th>
                                    </tr>
                                ))}
                            </thead>

                            <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr key={row.id}>
                                            {row.cells.map((cell) => {
                                                return (
                                                    <td
                                                        key={cell.column.id}
                                                        className={
                                                            cell.column.id ===
                                                                "detalhes" ||
                                                            cell.column.id ===
                                                                "editar"
                                                                ? "text-center"
                                                                : ""
                                                        }
                                                        {...cell.getCellProps()}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                );
                                            })}
                                            <td>
                                                {" "}
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary btn-sm text-light"
                                                    data-bs-toggle="modal"
                                                    data-bs-target={
                                                        "#" +
                                                        props.FiltersApi.modal
                                                    }
                                                    disabled={
                                                        props.FiltersApi.tag ===
                                                        ""
                                                            ? "disabled"
                                                            : ""
                                                    }
                                                    {...row.getRowProps()}
                                                    onClick={() => {
                                                        if (
                                                            props.FiltersApi &&
                                                            props.FiltersApi
                                                                .modal ===
                                                                "modalRegisterTemp"
                                                        ) {
                                                            props.setFiltersApi(
                                                                (Filters) => ({
                                                                    ...Filters,
                                                                    action: "edit",
                                                                    statusMsg:
                                                                        "",
                                                                    tagEdit:
                                                                        row
                                                                            .original
                                                                            .tag,
                                                                    data: row
                                                                        .original
                                                                        .data,
                                                                    hora: row
                                                                        .original
                                                                        .hora,
                                                                    tempAtual:
                                                                        row
                                                                            .original
                                                                            .tempAtual,
                                                                    tempMin:
                                                                        row
                                                                            .original
                                                                            .tempMin,
                                                                    tempMax:
                                                                        row
                                                                            .original
                                                                            .tempMax,
                                                                    pressao:
                                                                        row
                                                                            .original
                                                                            .pressao,
                                                                    urAtual:
                                                                        row
                                                                            .original
                                                                            .urAtual,
                                                                    urMinima:
                                                                        row
                                                                            .original
                                                                            .urMinima,
                                                                    urMaxima:
                                                                        row
                                                                            .original
                                                                            .urMaxima,
                                                                    log: row
                                                                        .original
                                                                        .log,
                                                                })
                                                            );
                                                        }

                                                        if (
                                                            props.FiltersApi &&
                                                            props.FiltersApi
                                                                .modal ===
                                                                "modalRegisterCleaning"
                                                        ) {
                                                            props.setFiltersApi(
                                                                (Filters) => ({
                                                                    ...Filters,
                                                                    action: "edit",
                                                                    statusMsg:
                                                                        "",
                                                                    tagEdit:
                                                                        row
                                                                            .original
                                                                            .tag,
                                                                    data: row
                                                                        .original
                                                                        .data,
                                                                    hora: row
                                                                        .original
                                                                        .hora,
                                                                    responsavel:
                                                                        row
                                                                            .original
                                                                            .responsavel,
                                                                    concat: row
                                                                        .original
                                                                        .concat,
                                                                })
                                                            );
                                                        }

                                                        if (
                                                            props.FiltersApi &&
                                                            props.FiltersApi
                                                                .modal ===
                                                                "modalRegisterEnvironmental"
                                                        ) {
                                                            props.setFiltersApi(
                                                                (Filters) => ({
                                                                    ...Filters,
                                                                    action: "edit",
                                                                    statusMsg:
                                                                        "",
                                                                    tagEdit:
                                                                        row
                                                                            .original
                                                                            .tag,
                                                                    data: row
                                                                        .original
                                                                        .data,
                                                                    hora: row
                                                                        .original
                                                                        .hora,
                                                                    teste1: row
                                                                        .original
                                                                        .teste1,
                                                                    teste2: row
                                                                        .original
                                                                        .teste2,
                                                                    teste3: row
                                                                        .original
                                                                        .teste3,
                                                                    concat: row
                                                                        .original
                                                                        .concat,
                                                                })
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <i className="bi bi-pencil-fill"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>

                    <TablePagination
                        nextPage={nextPage}
                        previousPage={previousPage}
                        canNextPage={canNextPage}
                        canPreviousPage={canPreviousPage}
                        pageOptions={pageOptions}
                        gotoPage={gotoPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        pageCount={pageCount}
                        pageIndex={pageIndex}
                        data={data}
                        downloadExcel={downloadExcel}
                    />
                </div>
            )}

            {/***Carregamento dos modais***/}
            {props.tableName === "cqTemp" && (
                <ModalRegisterTemp
                    setFiltersApi={props.setFiltersApi}
                    FiltersApi={props.FiltersApi}
                />
            )}

            {props.tableName === "cqCleaning" && (
                <ModalRegisterCleaning
                    setFiltersApi={props.setFiltersApi}
                    FiltersApi={props.FiltersApi}
                />
            )}

            {props.tableName === "cqEnvironmental" && (
                <ModalRegisterEnvironmental
                    setFiltersApi={props.setFiltersApi}
                    FiltersApi={props.FiltersApi}
                />
            )}
        </S.GlobalTable>
    );
};

export default GlobalTable;
