//Validação dos formulários
export function formValidate(formData, setFormValidateStatus) {
    //Expressões regulares
    const validatingName = new RegExp(
        /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ.\s']+$/
    );
    const validatingNumber = new RegExp("^[0-9]+$");

    // const validatingNameCard = new RegExp(/^[A-Za-z\s']+$/);
    // const validatingCardNumber = new RegExp(/^\d{13,19}$/);
    // const validatingExpirationDate = new RegExp(/^[\d\/]{7}$/);
    // const validatingSecurityCode = new RegExp(/^\d{3,4}$/);
    // const validatingCrm = new RegExp(/^\d{1,7}$/);
    // const validatingCpf = new RegExp(/^[0-9]{11}$/);
    // const validatingRg = new RegExp(/^[0-9xX]{9}$/);
    // const validatingEmail = new RegExp(/^[a-zA-Z0-9_.-]+@+[a-zA-Z0-9_.-]+$/);
    // const validatingTelephone = new RegExp(/^[0-9]{10,11}$/);
    // const validatingwhatsapp = new RegExp(/^[0-9]{11}$/);
    // const validatingDate = new RegExp(/(\d{4})-(\d{2})-(\d{2})/);
    // const validatingCep = new RegExp(/^[0-9]{8}$/);
    // const validatingStreet = new RegExp(
    //     /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\s']+$/
    // );
    // const validatingNeighborhood = new RegExp(
    //     /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\s']+$/
    // );
    // const validatingCity = new RegExp(
    //     /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\s']+$/
    // );
    // const validatingState = new RegExp(/^[a-zA-Z]{2}$/);
    // const validatingProfession = new RegExp(
    //     /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\s']+$/
    // );
    // const validatingPassword = new RegExp(
    //     /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#]{8,}$/
    // );

    //Validação Area log - Campo Tag
    if (formData && formData.action === "create" && formData.tag === "") {
        return setFormValidateStatus({
            type: "error",
            message: (
                <>
                    <i className="bi bi-exclamation-triangle-fill me-1"></i> O
                    campo <strong>Tag</strong> precisa ser preenchido!
                </>
            ),
        });
    }

    //Validação Area log - Campo Tag edit
    if (formData && formData.action === "edit" && formData.tagEdit === "") {
        return setFormValidateStatus({
            type: "error",
            message: (
                <>
                    <i className="bi bi-exclamation-triangle-fill me-1"></i> O
                    campo <strong>Tag</strong> precisa ser preenchido!
                </>
            ),
        });
    }

    //Validação Area log - Campo Data
    if (formData && formData.data === "") {
        return setFormValidateStatus({
            type: "error",
            message: (
                <>
                    <i className="bi bi-exclamation-triangle-fill me-1"></i> O
                    campo <strong>Data</strong> precisa ser preenchido!
                </>
            ),
        });
    }

    //Validação Area log - Campo Hora
    if (formData && formData.hora === "") {
        return setFormValidateStatus({
            type: "error",
            message: (
                <>
                    <i className="bi bi-exclamation-triangle-fill me-1"></i> O
                    campo <strong>Hora</strong> precisa ser preenchido!
                </>
            ),
        });
    }

    //Validação Area log - Campo Temperatura Atual
    if (
        formData &&
        formData.modal === "modalRegisterTemp" &&
        !validatingNumber.test(formData.tempAtual)
    ) {
        return setFormValidateStatus({
            type: "error",
            message: (
                <>
                    <i className="bi bi-exclamation-triangle-fill me-1"></i> O
                    campo <strong>Temperatura Atual</strong> precisa ser
                    preenchido (apenas números)!
                </>
            ),
        });
    }

    //Validação Area log - Campo Temperatura Min
    if (
        formData &&
        formData.modal === "modalRegisterTemp" &&
        !validatingNumber.test(formData.tempMin)
    ) {
        return setFormValidateStatus({
            type: "error",
            message: (
                <>
                    <i className="bi bi-exclamation-triangle-fill me-1"></i> O
                    campo <strong>Temperatura Min</strong> precisa ser
                    preenchido (apenas números)!
                </>
            ),
        });
    }

    //Validação Area log - Campo Temperatura Max
    if (
        formData &&
        formData.modal === "modalRegisterTemp" &&
        !validatingNumber.test(formData.tempMax)
    ) {
        return setFormValidateStatus({
            type: "error",
            message: (
                <>
                    <i className="bi bi-exclamation-triangle-fill me-1"></i> O
                    campo <strong>Temperatura Max</strong> precisa ser
                    preenchido (apenas números)!
                </>
            ),
        });
    }

    //Validação Area log - Campo Pressão
    if (
        formData &&
        formData.modal === "modalRegisterTemp" &&
        !validatingNumber.test(formData.pressao)
    ) {
        return setFormValidateStatus({
            type: "error",
            message: (
                <>
                    <i className="bi bi-exclamation-triangle-fill me-1"></i> O
                    campo <strong>Pressão</strong> precisa ser preenchido
                    (apenas números)!
                </>
            ),
        });
    }

    //Validação Area log - Campo UR Atual
    if (
        formData &&
        formData.modal === "modalRegisterTemp" &&
        !validatingNumber.test(formData.urAtual)
    ) {
        return setFormValidateStatus({
            type: "error",
            message: (
                <>
                    <i className="bi bi-exclamation-triangle-fill me-1"></i> O
                    campo <strong>UR Atual</strong> precisa ser preenchido
                    (apenas números)!
                </>
            ),
        });
    }

    //Validação Area log - Campo UR Min
    if (
        formData &&
        formData.modal === "modalRegisterTemp" &&
        !validatingNumber.test(formData.urMinima)
    ) {
        return setFormValidateStatus({
            type: "error",
            message: (
                <>
                    <i className="bi bi-exclamation-triangle-fill me-1"></i> O
                    campo <strong>UR Min</strong> precisa ser preenchido (apenas
                    números)!
                </>
            ),
        });
    }

    //Validação Area log - Campo UR Max
    if (
        formData &&
        formData.modal === "modalRegisterTemp" &&
        !validatingNumber.test(formData.urMaxima)
    ) {
        return setFormValidateStatus({
            type: "error",
            message: (
                <>
                    <i className="bi bi-exclamation-triangle-fill me-1"></i> O
                    campo <strong>UR Max</strong> precisa ser preenchido (apenas
                    números)!
                </>
            ),
        });
    }

    //Validação Area log - Campo Responsável
    if (
        formData &&
        formData.modal === "modalRegisterCleaning" &&
        !validatingName.test(formData.responsavel)
    ) {
        return setFormValidateStatus({
            type: "error",
            message: (
                <>
                    <i className="bi bi-exclamation-triangle-fill me-1"></i> O
                    campo <strong>Responsável</strong> precisa ser preenchido
                    (apenas letras)!
                </>
            ),
        });
    }

    //Validação Area log - Campo Teste1
    if (
        formData &&
        formData.modal === "modalRegisterEnvironmental" &&
        formData.teste1 === ""
    ) {
        return setFormValidateStatus({
            type: "error",
            message: (
                <>
                    <i className="bi bi-exclamation-triangle-fill me-1"></i> O
                    campo <strong>Teste 1</strong> precisa ser preenchido!
                </>
            ),
        });
    }

    //Validação Area log - Campo Teste2
    if (
        formData &&
        formData.modal === "modalRegisterEnvironmental" &&
        formData.teste2 === ""
    ) {
        return setFormValidateStatus({
            type: "error",
            message: (
                <>
                    <i className="bi bi-exclamation-triangle-fill me-1"></i> O
                    campo <strong>Teste 2</strong> precisa ser preenchido!
                </>
            ),
        });
    }

    //Validação Area log - Campo Teste3
    if (
        formData &&
        formData.modal === "modalRegisterEnvironmental" &&
        formData.teste3 === ""
    ) {
        return setFormValidateStatus({
            type: "error",
            message: (
                <>
                    <i className="bi bi-exclamation-triangle-fill me-1"></i> O
                    campo <strong>Teste 3</strong> precisa ser preenchido!
                </>
            ),
        });
    }

    //Validação Area log - Campo concat
    if (
        (formData &&
            formData.modal === "modalRegisterCleaning" &&
            formData.concat === "") ||
        (formData &&
            formData.modal === "modalRegisterEnvironmental" &&
            formData.concat === "")
    ) {
        return setFormValidateStatus({
            type: "error",
            message: (
                <>
                    <i className="bi bi-exclamation-triangle-fill me-1"></i> O
                    campo <strong>Concat</strong> precisa ser preenchido!
                </>
            ),
        });
    }

    //Validação cartão de crédito - Nome como escrito no cartão
    // if (
    //     (formData.Holder && !validatingNameCard.test(formData.Holder.value)) ||
    //     (formData.Holder && formData.Holder.value.length > 25)
    // ) {
    //     return setFormValidateStatus({
    //         type: "error",
    //         message: (
    //             <>
    //                 <i className="bi bi-exclamation-triangle-fill me-1"></i> O
    //                 campo <strong>"Nome como escrito no cartão"</strong> precisa
    //                 ser preenchido e não aceita caracteres especiais e
    //                 acentuação! <br />
    //                 <div className="mt-2">
    //                     * Tamanho máximo:{" "}
    //                     <strong className="ms-2">25 caracteres</strong>
    //                 </div>
    //             </>
    //         ),
    //     });
    // }

    return true;
}

export function formValidateSearch(formData, setFormValidateStatus) {
    //Validação Area log - Campo Tag
    if (formData && formData.tag === "") {
        return setFormValidateStatus({
            type: "error",
            message: (
                <>
                    <i className="bi bi-exclamation-triangle-fill me-1"></i> O
                    campo <strong>Tag</strong> precisa ser preenchido!
                </>
            ),
        });
    }

    //Validação Area log - Campo Data início
    if (formData && formData.dataInicio === "") {
        return setFormValidateStatus({
            type: "error",
            message: (
                <>
                    <i className="bi bi-exclamation-triangle-fill me-1"></i> O
                    campo <strong>Data início</strong> precisa ser preenchido!
                </>
            ),
        });
    }

    //Validação Area log - Campo Data final
    if (formData && formData.dataFinal === "") {
        return setFormValidateStatus({
            type: "error",
            message: (
                <>
                    <i className="bi bi-exclamation-triangle-fill me-1"></i> O
                    campo <strong>Data final</strong> precisa ser preenchido!
                </>
            ),
        });
    }

    return true;
}
