import moment from "moment";

export const TempColumns = [
    {
        Header: "Tags",
        accessor: "tag",
        disableSortBy: true,
    },
    {
        Header: "Data",
        accessor: "data",
        Cell: (props) => {
            const formatedDate = moment(props.value).format("DD/MM/yyyy");
            return <span>{formatedDate}</span>;
        },
    },
    {
        Header: "Horário",
        accessor: "hora",
        disableSortBy: true,
    },
    {
        Header: "Temp_Atual",
        accessor: "tempAtual",
        disableSortBy: true,
        Cell: (props) => {
            const value = props.value + "°C";
            return <span>{value}</span>;
        },
    },
    {
        Header: "Temp_Min",
        accessor: "tempMin",
        disableSortBy: true,
        Cell: (props) => {
            const value = props.value + "°C";
            return <span>{value}</span>;
        },
    },
    {
        Header: "Temp_Max",
        accessor: "tempMax",
        disableSortBy: true,
        Cell: (props) => {
            const value = props.value + "°C";
            return <span>{value}</span>;
        },
    },
    {
        Header: "Pressão",
        accessor: "pressao",
        disableSortBy: true,
    },
    {
        Header: "UR_Atual",
        accessor: "urAtual",
        disableSortBy: true,
        Cell: (props) => {
            const value = props.value + "%";
            return <span>{value}</span>;
        },
    },
    {
        Header: "UR_Min",
        accessor: "urMinima",
        disableSortBy: true,
        Cell: (props) => {
            const value = props.value + "%";
            return <span>{value}</span>;
        },
    },
    {
        Header: "UR_Max",
        accessor: "urMaxima",
        disableSortBy: true,
        Cell: (props) => {
            const value = props.value + "%";
            return <span>{value}</span>;
        },
    },
    {
        Header: "Log",
        accessor: "log",
        disableSortBy: true,
    },
];
