import styled from "styled-components";

export const TablePagination = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 100%;

    .div-table-right-options {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .div-table-left-options {
        display: flex;
        flex-direction: row;
        justify-content: left;
        width: 100%;
        margin-bottom: 15px;
    }

    .div-table-left-options .select-table-lines {
        width: auto;
    }

    @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;

        .div-table-left-options {
            width: 50%;
            margin-bottom: 0;
        }

        .div-table-right-options {
            justify-content: right;
            width: 50%;
        }
    }

    @media screen and (max-width: 500px) {
        .div-table-left-options {
            width: 100%;
            justify-content: space-between;
        }
    }
`;
