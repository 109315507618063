import React, { useContext } from "react";
import Loading from "./components/Loading/";

import {
    BrowserRouter as Routers,
    Route,
    Routes,
    Navigate,
} from "react-router-dom";

import LoginPage from "./pages/loginPage";
import HomePage from "./pages/homePage";
import Manip from "./pages/manipPage";
import Pcm from "./pages/pcmPage";
import CqDashboard from "./pages/cqDashboardPage";
import CqTemp from "./pages/cqTempPage";
import CqCleaning from "./pages/cqCleaningPage";
import CqEnvironmental from "./pages/cqEnvironmentalPage";

import { AuthProvider, AuthContext } from "./contexts/auth";

const AppRoutes = () => {
    const Private = ({ children }) => {
        const { authenticated, loading } = useContext(AuthContext);

        if (loading) {
            return <Loading className="loading" />;
        }

        if (!authenticated) {
            return <Navigate to="/login" />;
        }

        return children;
    };

    return (
        <Routers>
            <AuthProvider>
                <Routes>
                    <Route exact path="/login" element={<LoginPage />} />
                    <Route
                        exact
                        path="/"
                        element={
                            <Private>
                                <HomePage />
                            </Private>
                        }
                    />
                    <Route
                        exact
                        path="/manip"
                        element={
                            <Private>
                                <Manip />
                            </Private>
                        }
                    />
                    <Route
                        exact
                        path="/pcm"
                        element={
                            <Private>
                                <Pcm />
                            </Private>
                        }
                    />
                    <Route
                        exact
                        path="/cqDashboard"
                        element={
                            <Private>
                                <CqDashboard />
                            </Private>
                        }
                    />
                    <Route
                        exact
                        path="/cqTemp"
                        element={
                            <Private>
                                <CqTemp />
                            </Private>
                        }
                    />
                    <Route
                        exact
                        path="/cqCleaning"
                        element={
                            <Private>
                                <CqCleaning />
                            </Private>
                        }
                    />
                    <Route
                        exact
                        path="/cqEnvironmental"
                        element={
                            <Private>
                                <CqEnvironmental />
                            </Private>
                        }
                    />
                </Routes>
            </AuthProvider>
        </Routers>
    );
};

export default AppRoutes;
