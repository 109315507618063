import React from "react";
import * as S from "./styles";

const ColumnOptions = (props) => {
    //console.log(props.FiltersApi);

    return (
        <S.Columns className="mb-3">
            <div className="input-group div-input-search">
                <input
                    type="text"
                    className="form-control me-2"
                    placeholder="Filtrar"
                    aria-label="Pesquisar"
                    aria-describedby="basic-addon1"
                    value={props.globalFilter || ""}
                    onChange={(e) => props.setGlobalFilter(e.target.value)}
                />
            </div>

            <button
                type="button"
                className="btn btn-primary btn-register"
                data-bs-toggle="modal"
                data-bs-target={"#" + props.FiltersApi.modal}
                disabled={props.FiltersApi.tag === "" ? "disabled" : ""}
                onClick={() => {
                    props.setFiltersApi((Filters) => ({
                        ...Filters,
                        action: "create",
                        statusMsg: "",
                        salaEdit: "",
                        data: "",
                        hora: "",
                        tempAtual: "",
                        tempMin: "",
                        tempMax: "",
                        pressao: "",
                        urAtual: "",
                        urMinima: "",
                        urMaxima: "",
                        log: "",
                        responsavel: "",
                        concat: "",
                        teste1: "",
                        teste2: "",
                        teste3: "",
                    }));
                }}
            >
                <i className="bi bi-plus-lg"></i> Registro
            </button>
        </S.Columns>
    );
};

export default ColumnOptions;
