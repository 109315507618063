import styled from "styled-components";

export const FiltersCq = styled.div`
    .div-filters {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        background-color: #f0f5ff;
        border-radius: 7px 7px 0 0;
        padding: 15px 15px;
    }

    .div-filters select,
    .div-filters input,
    .div-filters button {
        width: auto;
    }

    @media only screen and (max-width: 1024px) {
        display: none;
    }

    @media screen and (max-width: 860px) {
        .div-filters {
            flex-direction: column;
            align-items: start;
        }

        .div-filters select,
        .div-filters input,
        .div-filters button {
            width: 100%;
        }

        .div-filters select {
            margin-bottom: 15px;
        }

        .div-filters button {
            margin-top: 15px;
        }

        .div-filters span {
            margin-bottom: 5px;
        }
    }
`;
