import React from "react";
import { Link } from "react-router-dom";
import * as S from "./styles";

const SideBarCq = (props) => {
    let optionDashboard = "";
    let optionTemp = "";
    let optionCleaning = "";
    let optionEnvironmental = "";

    //Verifica a página atual
    if (props.page === "cqDashboard") {
        optionDashboard = "active-option";
    }

    if (props.page === "cqTemp") {
        optionTemp = "active-option";
    }

    if (props.page === "cqCleaning") {
        optionCleaning = "active-option";
    }

    if (props.page === "cqEnvironmental") {
        optionEnvironmental = "active-option";
    }

    return (
        <S.SideBarCq>
            <nav>
                <Link aria-current="page" to="/cqDashboard">
                    <span className={optionDashboard}>
                        <i className="bi bi-graph-down"></i>
                        <span>Dashboard</span>
                    </span>
                </Link>

                <Link aria-current="page" to="/cqTemp">
                    <span className={optionTemp}>
                        <i className="bi bi-thermometer-half"></i>
                        <span>Temp / UR / Pressão</span>
                    </span>
                </Link>

                <Link to="/cqCleaning">
                    <span className={optionCleaning}>
                        <i className="bi bi-stars"></i>
                        <span>Limpeza</span>
                    </span>
                </Link>
                <Link to="/cqEnvironmental">
                    <span className={optionEnvironmental}>
                        <i className="bi bi-tree-fill"></i>
                        <span>Ambiental</span>
                    </span>
                </Link>
            </nav>
        </S.SideBarCq>
    );
};

export default SideBarCq;
