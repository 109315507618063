import styled from "styled-components";

export const GlobalTable = styled.div`
    .div-main-table {
        padding: 15px 10px;
        border: 10px solid #f0f5ff;
        border-radius: 7px;
    }

    .div-table {
        border: 1px solid #ddd;
        border-radius: 7px;
        margin-bottom: 30px;
        height: 480px;
        overflow-y: auto;
        padding: 20px;
        padding-top: 0;
    }

    .table {
        width: 100%;
        margin-left: -1px;
        margin-bottom: 0;
    }

    .table thead {
        width: 100%;
    }

    .table thead tr th {
        position: sticky;
        top: 0px;
        background-color: #fff;
        padding-top: 15px;
        color: #013b82;
        z-index: 1000;
    }

    .table thead tr th .thead-div-arrow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .table thead tr th .thead-div-arrow i {
        font-size: 0.6rem;
    }

    .table thead tr th .div-thead-arrow-up-down {
        display: flex;
        flex-direction: column;
    }

    .table thead tr th .div-thead-arrow-up-down i {
        margin-bottom: -7px;
    }

    .table thead tr .th-tags {
        min-width: 120px;
    }

    .table thead tr .th-log {
        min-width: 200px;
    }

    @media only screen and (max-width: 1024px) {
        .div-main-table {
            display: none;
        }
    }
`;
