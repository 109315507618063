import React, { useEffect, useRef, useState } from "react";
import { Html5Qrcode } from "html5-qrcode";
import { getTags } from "../../../services/api";
import * as S from "./styles";

const QrCodeScanner = (props) => {
    const [qrCodeResult, setQrCodeResult] = useState("");
    const [Tags, setTags] = useState([]);
    const html5QrCodeRef = useRef(null);

    //variavel para lidar com as mensagens de erro ou sucesso
    const [tagValidateStatus, setTagValidateStatus] = useState({
        type: "",
        message: "",
    });

    useEffect(() => {
        (async () => {
            const response = await getTags();

            if (response.status === 200) {
                setTags(response.data.tags);
                //setLoading(false);
            } else {
                if (!response.data) {
                    console.log(response.message);
                }
                //setLoading(false);
            }
        })();
    }, []);

    function qrCodeValidation(qrCodeResult) {
        //Verificando se a Tag existe
        const existsTag = Tags.some((innerArray) =>
            innerArray.includes(qrCodeResult)
        );

        if (existsTag === true) {
            props.setFiltersApi((Filters) => ({
                ...Filters,
                tag: qrCodeResult,
            }));
        } else {
            setTagValidateStatus({
                type: "error",
                message: (
                    <>
                        <i className="bi bi-exclamation-triangle-fill me-1"></i>{" "}
                        A <b>Tag</b> não é válida.
                    </>
                ),
            });
        }
    }

    const startScan = () => {
        const html5QrCode = new Html5Qrcode("qr-reader");

        // Inicia o scanner
        html5QrCode
            .start(
                { facingMode: "environment" }, // Usando a câmera traseira
                {
                    fps: 10, // Frames por segundo
                    qrbox: 180, // Tamanho da caixa de leitura do QR code
                },
                (decodedText) => {
                    // Lógica quando um QR code é lido
                    console.log(`Código QR lido: ${decodedText}`);
                    qrCodeValidation(decodedText);
                    setQrCodeResult(decodedText);

                    html5QrCode
                        .stop()
                        .then(() => {
                            console.log("Scanner parado.");
                        })
                        .catch((err) => {
                            console.error("Erro ao parar o scanner: ", err);
                        });
                },
                (errorMessage) => {
                    // Lógica quando há um erro de leitura
                    console.log(`Erro ao ler QR Code: ${errorMessage}`);
                }
            )
            .catch((err) => {
                // Lógica quando o scanner não consegue iniciar
                console.error("Erro ao iniciar o scanner: ", err);
            });

        // Salva a referência para poder parar o scanner depois
        html5QrCodeRef.current = html5QrCode;
    };

    const stopScan = () => {
        if (html5QrCodeRef.current) {
            html5QrCodeRef.current
                .stop()
                .then(() => {
                    console.log("Scanner parado.");
                })
                .catch((err) => {
                    console.error("Erro ao parar o scanner: ", err);
                });
        }
    };

    return (
        <S.QrCodeScanner>
            <div className="mb-4">
                <i className="bi bi-info-circle-fill"></i> Para iniciar um
                registro você deve escanear a <b>Tag</b>.
            </div>

            {tagValidateStatus && tagValidateStatus.type === "error" && (
                <div
                    className="alert alert-danger sticky-top mb-3"
                    role="alert"
                >
                    {tagValidateStatus.message}
                </div>
            )}

            <div
                className="mb-1"
                id="qr-reader"
                style={{ maxWidth: "100%" }}
            ></div>

            <div className="d-flex w-100 mb-3">
                <button
                    className="btn btn-dark btn-scan me-2 w-100"
                    onClick={() => {
                        setTagValidateStatus({
                            type: "",
                            message: "",
                        });
                        startScan();
                    }}
                >
                    <i className="bi bi-qr-code-scan me-2"></i> Escanear Tag
                </button>
                <button className="btn btn-danger" onClick={stopScan}>
                    <i className="bi bi-stop-fill"></i>
                </button>
            </div>

            {/* Exibe o resultado do QR Code */}
            <div className="d-flex  align-items-center border border-secondary p-3 mb-3">
                <div className="div-qrcode-icon me-4">
                    <i className="bi bi-qr-code"></i>
                </div>

                <div>
                    {qrCodeResult ? (
                        <span>
                            <b>{qrCodeResult}</b>
                        </span>
                    ) : (
                        <span className="text-black-50">
                            Tag não encontrada.
                        </span>
                    )}
                </div>
            </div>

            <button
                type="button"
                className="btn btn-primary btn-lg w-100"
                data-bs-toggle="modal"
                data-bs-target={"#" + props.FiltersApi.modal}
                disabled={props.FiltersApi.tag === "" ? "disabled" : ""}
                onClick={() => {
                    props.setFiltersApi((Filters) => ({
                        ...Filters,
                        action: "create",
                        statusMsg: "",
                        salaEdit: "",
                        data: "",
                        hora: "",
                        tempAtual: "",
                        tempMin: "",
                        tempMax: "",
                        pressao: "",
                        urAtual: "",
                        urMinima: "",
                        urMaxima: "",
                        log: "",
                        responsavel: "",
                        concat: "",
                        teste1: "",
                        teste2: "",
                        teste3: "",
                    }));
                }}
            >
                <i className="bi bi-plus-lg"></i> Novo registro
            </button>
        </S.QrCodeScanner>
    );
};

export default QrCodeScanner;
