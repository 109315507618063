import moment from "moment";

export const EnvironmentalColumns = [
    {
        Header: "Tags",
        accessor: "tag",
        disableSortBy: true,
    },
    {
        Header: "Data",
        accessor: "data",
        Cell: (props) => {
            const formatedDate = moment(props.value).format("DD/MM/yyyy");
            return <span>{formatedDate}</span>;
        },
    },
    {
        Header: "Horário",
        accessor: "hora",
        disableSortBy: true,
    },
    {
        Header: "Teste1",
        accessor: "teste1",
        disableSortBy: true,
    },
    {
        Header: "Teste2",
        accessor: "teste2",
        disableSortBy: true,
    },
    {
        Header: "Teste3",
        accessor: "teste3",
        disableSortBy: true,
    },
    {
        Header: "Concat",
        accessor: "concat",
        disableSortBy: true,
    },
];
