import styled from "styled-components";

export const ModalRegisterCleaning = styled.div`
    .modal-cq .modal-content .modal-header {
        background-color: #f0f5ff;
    }

    .modal-cq .modal-content .modal-header h1 {
        color: #013b82;
        font-weight: bold;
    }

    .modal-cq .modal-content .modal-header h1 i {
        color: #013b82;
        font-size: 1.1rem;
    }

    .modal-cq .modal-content .modal-header h1 i {
        font-size: 1.2rem;
    }

    .modal-cq .modal-content .modal-body {
        padding: 30px;
    }

    .modal-cq .modal-content .modal-body label {
        color: #013b82;
    }

    .modal-cq .modal-content .modal-footer button .div-btn-loading {
        width: 18px;
        height: 18px;
    }
`;
