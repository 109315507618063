import styled from "styled-components";

export const Columns = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .div-input-search {
        width: 100%;
    }

    .div-input-search input {
        border-color: #00377fb3;
    }

    .div-input-search span {
        background-color: #fff;
    }

    .btn-register {
        min-width: 120px;
    }

    @media screen and (min-width: 1200px) {
        .div-input-search {
            max-width: 300px;
        }
    }
`;
