import React, { useState, useEffect } from "react";
//import { AuthContext } from "../../contexts/auth";
import Headder from "../../components/Headder";
import SideBar from "../../components/TableCq/SideBarCq";
import Titles from "../../components/TableCq/TitlesCq";
import Table from "../../components/TableCq/Table";
import FiltersCq from "../../components/TableCq/FiltersCq";
import { checkTokenValidity } from "../../services/api";
import QrCodeScanner from "../../components/TableCq/QrCodeScanner";
import Loading from "../../components/Loading";
import * as S from "./styles";

const CqTempPage = () => {
    //Checando a validade do token
    if (checkTokenValidity()) {
        // Recarregar a página
        window.location.reload();
    }

    //const { User } = useContext(AuthContext);

    const [Temp, setTemp] = useState([]);

    //Filtro escolhido pelo usuário para buscar na API
    const [FiltersApi, setFiltersApi] = useState({
        tag: "",
        dataInicio: "",
        dataFinal: "",
        pagina: "Temp / OR / Pressão",
        icone: "bi bi-thermometer-half",
        modal: "modalRegisterTemp",
        action: "search",
        statusMsg: "",
        salaEdit: "",
        data: "",
        hora: "",
        tempAtual: "",
        tempMin: "",
        tempMax: "",
        pressao: "",
        urAtual: "",
        urMinima: "",
        urMaxima: "",
        log: "",
    });

    //Ativa ou deasativa o loading
    const [loading, setLoading] = useState(true);

    //Variáveis de erros
    const [Error, setError] = useState("");
    const [StatusError, setStatusError] = useState(false);
    const [StatusGetTags, setStatusGetTags] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            if (StatusGetTags === true) {
                (async () => {
                    //const response = await getDoctors(User.codClinic);

                    const response = [
                        {
                            tag: "IRERE-SA01",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 27,
                            tempMin: 24,
                            tempMax: 29,
                            pressao: 9,
                            urAtual: 26,
                            urMinima: 25,
                            urMaxima: 23,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "IRERE-SA02",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 29,
                            tempMin: 16,
                            tempMax: 18,
                            pressao: 10,
                            urAtual: 31,
                            urMinima: 20,
                            urMaxima: 35,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "IRERE-SA03",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 28,
                            tempMin: 23,
                            tempMax: 19,
                            pressao: 7,
                            urAtual: 19,
                            urMinima: 18,
                            urMaxima: 30,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "IRERE-SA04",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 15,
                            tempMin: 27,
                            tempMax: 24,
                            pressao: 10,
                            urAtual: 15,
                            urMinima: 27,
                            urMaxima: 34,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "IRERE-SA05",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 15,
                            tempMin: 20,
                            tempMax: 26,
                            pressao: 10,
                            urAtual: 26,
                            urMinima: 18,
                            urMaxima: 28,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "IRERE-SA06",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 26,
                            tempMin: 26,
                            tempMax: 24,
                            pressao: 8,
                            urAtual: 22,
                            urMinima: 31,
                            urMaxima: 27,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "IRERE-SA07",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 27,
                            tempMin: 25,
                            tempMax: 28,
                            pressao: 5,
                            urAtual: 22,
                            urMinima: 19,
                            urMaxima: 27,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "IRERE-SA08",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 27,
                            tempMin: 18,
                            tempMax: 25,
                            pressao: 8,
                            urAtual: 31,
                            urMinima: 31,
                            urMaxima: 30,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "IRERE-SA09",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 20,
                            tempMin: 27,
                            tempMax: 26,
                            pressao: 8,
                            urAtual: 25,
                            urMinima: 33,
                            urMaxima: 15,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "IRERE-SA10",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 27,
                            tempMin: 20,
                            tempMax: 23,
                            pressao: 10,
                            urAtual: 34,
                            urMinima: 31,
                            urMaxima: 31,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "BRIGADEIRO-SA01",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 17,
                            tempMin: 20,
                            tempMax: 23,
                            pressao: 8,
                            urAtual: 23,
                            urMinima: 26,
                            urMaxima: 16,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "BRIGADEIRO-SA02",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 29,
                            tempMin: 23,
                            tempMax: 17,
                            pressao: 5,
                            urAtual: 34,
                            urMinima: 17,
                            urMaxima: 19,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "BRIGADEIRO-SA03",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 27,
                            tempMin: 16,
                            tempMax: 20,
                            pressao: 5,
                            urAtual: 33,
                            urMinima: 16,
                            urMaxima: 18,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "BRIGADEIRO-SA04",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 22,
                            tempMin: 28,
                            tempMax: 24,
                            pressao: 6,
                            urAtual: 25,
                            urMinima: 31,
                            urMaxima: 26,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "BRIGADEIRO-SA05",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 24,
                            tempMin: 21,
                            tempMax: 28,
                            pressao: 9,
                            urAtual: 15,
                            urMinima: 23,
                            urMaxima: 22,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "BRIGADEIRO-SA06",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 16,
                            tempMin: 18,
                            tempMax: 23,
                            pressao: 5,
                            urAtual: 30,
                            urMinima: 35,
                            urMaxima: 28,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "BRIGADEIRO-SA07",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 28,
                            tempMin: 21,
                            tempMax: 27,
                            pressao: 8,
                            urAtual: 31,
                            urMinima: 19,
                            urMaxima: 22,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "BRIGADEIRO-SA08",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 29,
                            tempMin: 16,
                            tempMax: 18,
                            pressao: 10,
                            urAtual: 30,
                            urMinima: 17,
                            urMaxima: 28,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "BRIGADEIRO-SA09",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 23,
                            tempMin: 20,
                            tempMax: 16,
                            pressao: 5,
                            urAtual: 32,
                            urMinima: 28,
                            urMaxima: 34,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "BRIGADEIRO-SA10",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 20,
                            tempMin: 19,
                            tempMax: 17,
                            pressao: 9,
                            urAtual: 22,
                            urMinima: 33,
                            urMaxima: 23,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "JABAQUARA-SA01",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 21,
                            tempMin: 18,
                            tempMax: 26,
                            pressao: 10,
                            urAtual: 32,
                            urMinima: 17,
                            urMaxima: 22,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "JABAQUARA-SA02",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 17,
                            tempMin: 21,
                            tempMax: 16,
                            pressao: 10,
                            urAtual: 17,
                            urMinima: 34,
                            urMaxima: 29,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "JABAQUARA-SA03",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 28,
                            tempMin: 15,
                            tempMax: 25,
                            pressao: 9,
                            urAtual: 31,
                            urMinima: 31,
                            urMaxima: 23,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "JABAQUARA-SA04",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 26,
                            tempMin: 19,
                            tempMax: 22,
                            pressao: 8,
                            urAtual: 28,
                            urMinima: 20,
                            urMaxima: 21,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "JABAQUARA-SA05",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 23,
                            tempMin: 22,
                            tempMax: 24,
                            pressao: 5,
                            urAtual: 23,
                            urMinima: 27,
                            urMaxima: 18,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "JABAQUARA-SA06",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 17,
                            tempMin: 19,
                            tempMax: 28,
                            pressao: 9,
                            urAtual: 20,
                            urMinima: 30,
                            urMaxima: 29,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "JABAQUARA-SA07",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 19,
                            tempMin: 21,
                            tempMax: 24,
                            pressao: 6,
                            urAtual: 21,
                            urMinima: 24,
                            urMaxima: 31,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "JABAQUARA-SA08",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 24,
                            tempMin: 27,
                            tempMax: 20,
                            pressao: 10,
                            urAtual: 23,
                            urMinima: 17,
                            urMaxima: 32,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "JABAQUARA-SA09",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 20,
                            tempMin: 15,
                            tempMax: 29,
                            pressao: 5,
                            urAtual: 28,
                            urMinima: 34,
                            urMaxima: 22,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                        {
                            tag: "JABAQUARA-SA10",
                            data: "2024-09-01",
                            hora: "07:00",
                            tempAtual: 23,
                            tempMin: 28,
                            tempMax: 18,
                            pressao: 10,
                            urAtual: 26,
                            urMinima: 28,
                            urMaxima: 19,
                            log: "USUÁRIO HORARIO E ETC",
                        },
                    ];

                    const status = 200;

                    //if (response.status === 200) {
                    if (status === 200) {
                        //settemp(response.data.medicos);
                        setTemp(response);
                        setStatusGetTags(false);
                        setLoading(false);
                    } else {
                        if (!response.data) {
                            setError(response.message);
                            //console.log(response);
                        }
                        setStatusError(true);
                        setStatusGetTags(false);
                        setLoading(false);
                    }
                })();
            }
        }, "1000");
    }, [StatusGetTags]);

    return (
        <S.CqTempPage>
            {loading !== false && <Loading className="loading" />}
            <Headder />
            <SideBar page="cqTemp" />

            <div className="main-cq-temp div-temp">
                <div className="container-fluid">
                    <Titles
                        title="Temp / UR / Pressão"
                        icon="bi bi-thermometer-half"
                    />

                    <QrCodeScanner
                        setFiltersApi={setFiltersApi}
                        FiltersApi={FiltersApi}
                    />

                    <FiltersCq
                        setFiltersApi={setFiltersApi}
                        FiltersApi={FiltersApi}
                        setStatusGetTags={setStatusGetTags}
                        setLoading={setLoading}
                    />

                    {StatusError && (
                        <div
                            className="alert alert-danger mt-3 mb-4 sticky-top"
                            role="alert"
                        >
                            <i className="bi bi-exclamation-triangle-fill me-1"></i>{" "}
                            Erro ao carregar a página! Se o problema persistir
                            entre em contato com <strong>Administrador</strong>.
                            <div className="mt-2">
                                <b>Error:</b> {Error}
                            </div>
                        </div>
                    )}

                    {StatusError === false && loading === false && (
                        <Table
                            data={Temp}
                            tableName="cqTemp"
                            setFiltersApi={setFiltersApi}
                            FiltersApi={FiltersApi}
                        />
                    )}
                </div>
            </div>
        </S.CqTempPage>
    );
};

export default CqTempPage;
