import React, { useState, useEffect } from "react";
import Headder from "../../components/Headder";
import SideBar from "../../components/TableCq/SideBarCq";
import Titles from "../../components/TableCq/TitlesCq";
import { checkTokenValidity } from "../../services/api";
import Loading from "../../components/Loading";
import * as S from "./styles";

const CqDashboardPage = () => {
    //Checando a validade do token
    if (checkTokenValidity()) {
        // Recarregar a página
        window.location.reload();
    }

    //Ativa ou deasativa o loading
    const [loading, setLoading] = useState(true);

    //Variáveis de erros
    const [Error, setError] = useState("");
    const [StatusError, setStatusError] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            (async () => {
                //const response = await getDoctors(User.codClinic);

                const response = [
                    {
                        sala: "IRERE-SA01",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "27,01°",
                        tempMin: "24,47°",
                        tempMax: "29,52°",
                        pressao: 9,
                        urAtual: 26,
                        urMinima: 25,
                        urMaxima: 23,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "IRERE-SA02",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "29,78°",
                        tempMin: "16,92°",
                        tempMax: "18,22°",
                        pressao: 10,
                        urAtual: 31,
                        urMinima: 20,
                        urMaxima: 35,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "IRERE-SA03",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "28,22°",
                        tempMin: "23,96°",
                        tempMax: "19,38°",
                        pressao: 7,
                        urAtual: 19,
                        urMinima: 18,
                        urMaxima: 30,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "IRERE-SA04",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "15,31°",
                        tempMin: "27,82°",
                        tempMax: "24,07°",
                        pressao: 10,
                        urAtual: 15,
                        urMinima: 27,
                        urMaxima: 34,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "IRERE-SA05",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "15,83°",
                        tempMin: "20,99°",
                        tempMax: "26,11°",
                        pressao: 10,
                        urAtual: 26,
                        urMinima: 18,
                        urMaxima: 28,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "IRERE-SA06",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "26,52°",
                        tempMin: "26,97°",
                        tempMax: "24,62°",
                        pressao: 8,
                        urAtual: 22,
                        urMinima: 31,
                        urMaxima: 27,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "IRERE-SA07",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "27,26°",
                        tempMin: "25,96°",
                        tempMax: "28,86°",
                        pressao: 5,
                        urAtual: 22,
                        urMinima: 19,
                        urMaxima: 27,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "IRERE-SA08",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "27,24°",
                        tempMin: "18,24°",
                        tempMax: "25,06°",
                        pressao: 8,
                        urAtual: 31,
                        urMinima: 31,
                        urMaxima: 30,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "IRERE-SA09",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "20,86°",
                        tempMin: "27,14°",
                        tempMax: "26,33°",
                        pressao: 8,
                        urAtual: 25,
                        urMinima: 33,
                        urMaxima: 15,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "IRERE-SA10",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "27,77°",
                        tempMin: "20,25°",
                        tempMax: "23,68°",
                        pressao: 10,
                        urAtual: 34,
                        urMinima: 31,
                        urMaxima: 31,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "BRIGADEIRO-SA01",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "17,18°",
                        tempMin: "20,26°",
                        tempMax: "23,56°",
                        pressao: 8,
                        urAtual: 23,
                        urMinima: 26,
                        urMaxima: 16,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "BRIGADEIRO-SA02",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "29,39°",
                        tempMin: "23,22°",
                        tempMax: "17,60°",
                        pressao: 5,
                        urAtual: 34,
                        urMinima: 17,
                        urMaxima: 19,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "BRIGADEIRO-SA03",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "27,85°",
                        tempMin: "16,21°",
                        tempMax: "20,74°",
                        pressao: 5,
                        urAtual: 33,
                        urMinima: 16,
                        urMaxima: 18,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "BRIGADEIRO-SA04",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "22,97°",
                        tempMin: "28,95°",
                        tempMax: "24,73°",
                        pressao: 6,
                        urAtual: 25,
                        urMinima: 31,
                        urMaxima: 26,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "BRIGADEIRO-SA05",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "24,09°",
                        tempMin: "21,29°",
                        tempMax: "28,49°",
                        pressao: 9,
                        urAtual: 15,
                        urMinima: 23,
                        urMaxima: 22,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "BRIGADEIRO-SA06",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "16,06°",
                        tempMin: "18,55°",
                        tempMax: "23,24°",
                        pressao: 5,
                        urAtual: 30,
                        urMinima: 35,
                        urMaxima: 28,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "BRIGADEIRO-SA07",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "28,91°",
                        tempMin: "21,43°",
                        tempMax: "27,06°",
                        pressao: 8,
                        urAtual: 31,
                        urMinima: 19,
                        urMaxima: 22,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "BRIGADEIRO-SA08",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "29,98°",
                        tempMin: "16,65°",
                        tempMax: "18,09°",
                        pressao: 10,
                        urAtual: 30,
                        urMinima: 17,
                        urMaxima: 28,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "BRIGADEIRO-SA09",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "23,00°",
                        tempMin: "20,43°",
                        tempMax: "16,19°",
                        pressao: 5,
                        urAtual: 32,
                        urMinima: 28,
                        urMaxima: 34,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "BRIGADEIRO-SA10",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "20,16°",
                        tempMin: "19,99°",
                        tempMax: "17,67°",
                        pressao: 9,
                        urAtual: 22,
                        urMinima: 33,
                        urMaxima: 23,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "JABAQUARA-SA01",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "21,53°",
                        tempMin: "18,68°",
                        tempMax: "26,95°",
                        pressao: 10,
                        urAtual: 32,
                        urMinima: 17,
                        urMaxima: 22,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "JABAQUARA-SA02",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "17,60°",
                        tempMin: "21,66°",
                        tempMax: "16,56°",
                        pressao: 10,
                        urAtual: 17,
                        urMinima: 34,
                        urMaxima: 29,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "JABAQUARA-SA03",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "28,80°",
                        tempMin: "15,15°",
                        tempMax: "25,38°",
                        pressao: 9,
                        urAtual: 31,
                        urMinima: 31,
                        urMaxima: 23,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "JABAQUARA-SA04",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "26,95°",
                        tempMin: "19,88°",
                        tempMax: "22,02°",
                        pressao: 8,
                        urAtual: 28,
                        urMinima: 20,
                        urMaxima: 21,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "JABAQUARA-SA05",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "23,68°",
                        tempMin: "22,54°",
                        tempMax: "24,43°",
                        pressao: 5,
                        urAtual: 23,
                        urMinima: 27,
                        urMaxima: 18,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "JABAQUARA-SA06",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "17,88°",
                        tempMin: "19,59°",
                        tempMax: "28,05°",
                        pressao: 9,
                        urAtual: 20,
                        urMinima: 30,
                        urMaxima: 29,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "JABAQUARA-SA07",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "19,26°",
                        tempMin: "21,67°",
                        tempMax: "24,55°",
                        pressao: 6,
                        urAtual: 21,
                        urMinima: 24,
                        urMaxima: 31,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "JABAQUARA-SA08",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "24,14°",
                        tempMin: "27,26°",
                        tempMax: "20,32°",
                        pressao: 10,
                        urAtual: 23,
                        urMinima: 17,
                        urMaxima: 32,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "JABAQUARA-SA09",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "20,11°",
                        tempMin: "15,49°",
                        tempMax: "29,12°",
                        pressao: 5,
                        urAtual: 28,
                        urMinima: 34,
                        urMaxima: 22,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                    {
                        sala: "JABAQUARA-SA10",
                        data: "01/09/2024",
                        hora: 1,
                        tempAtual: "23,68°",
                        tempMin: "28,19°",
                        tempMax: "18,07°",
                        pressao: 10,
                        urAtual: 26,
                        urMinima: 28,
                        urMaxima: 19,
                        log: "USUÁRIO HORARIO E ETC",
                    },
                ];

                const status = 200;

                //if (response.status === 200) {
                if (status === 200) {
                    //settemp(response.data.medicos);
                    setLoading(false);
                } else {
                    if (!response.data) {
                        setError(response.message);
                        //console.log(response);
                    }
                    setStatusError(true);
                    setLoading(false);
                }
            })();
        }, "1000");
    }, []);

    return (
        <S.CqDashboardPage>
            {loading !== false && <Loading className="loading" />}
            <Headder />
            <SideBar page="cqDashboard" />

            <div className="main-cq-dashboard div-temp">
                <div className="container-fluid">
                    <Titles title="Dashboard" icon="bi bi-graph-down" />

                    {StatusError && (
                        <div
                            className="alert alert-danger mt-3 mb-4 sticky-top"
                            role="alert"
                        >
                            <i className="bi bi-exclamation-triangle-fill me-1"></i>{" "}
                            Erro ao carregar a página! Se o problema persistir
                            entre em contato com <strong>Administrador</strong>.
                            <div className="mt-2">
                                <b>Error:</b> {Error}
                            </div>
                        </div>
                    )}

                    {StatusError === false && loading === false && (
                        <div className="alert alert-dark" role="alert">
                            <i className="bi bi-info-circle-fill"></i>&nbsp;
                            Página em construção.
                        </div>
                    )}
                </div>
            </div>
        </S.CqDashboardPage>
    );
};

export default CqDashboardPage;
