import React, { useState, useEffect } from "react";
import { getTags } from "../../../services/api";
import { checkTokenValidity } from "../../../services/api";
import { formValidateSearch } from "../../../utils/utils";
import * as S from "./styles";

const FiltersCq = (props) => {
    //console.log(props.FiltersApi);

    const [Tags, setTags] = useState([]);

    //Ativa ou deasativa o loading
    const [loading, setLoading] = useState(true);

    //variavel para lidar com as mensagens de erro ou sucesso
    const [formValidateStatusSearch, setFormValidateStatusSearch] = useState({
        type: "",
        message: "",
    });

    useEffect(() => {
        (async () => {
            const response = await getTags();

            if (response.status === 200) {
                setTags(response.data.tags);
                setLoading(false);
            } else {
                if (!response.data) {
                    console.log(response.message);
                }
                setLoading(false);
            }
        })();
    }, []);

    //Lidando com o submit do formulário
    const handleSumit = async () => {
        //Checando a validade do token
        if (checkTokenValidity()) {
            // Recarregar a página
            window.location.reload();
        }

        //Removendo o alerta caso ele exista
        props.setFiltersApi((Filters) => ({
            ...Filters,
            statusMsg: false,
        }));

        //Validando o formulário local
        if (
            !formValidateSearch(props.FiltersApi, setFormValidateStatusSearch)
        ) {
            //Voltar ai topo da página
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

            setTimeout(() => {
                props.setFiltersApi((Filters) => ({
                    ...Filters,
                    statusMsg: true,
                }));
            }, 300);

            return;
        } else {
            props.setStatusGetTags(true);
            props.setLoading(true);
        }
    };

    return (
        <S.FiltersCq>
            <div>
                <p>
                    <i className="bi bi-info-circle-fill"></i> Escolha uma{" "}
                    <b>Tag</b> para iniciar.
                </p>
            </div>

            {props.FiltersApi.statusMsg === true &&
                props.FiltersApi.action === "search" &&
                formValidateStatusSearch.type === "error" && (
                    <div className="alert alert-danger sticky-top" role="alert">
                        {formValidateStatusSearch.message}
                    </div>
                )}
            {props.FiltersApi.statusMsg === true &&
                props.FiltersApi.action === "search" &&
                formValidateStatusSearch.type === "success" && (
                    <div
                        className="alert alert-success sticky-top"
                        role="alert"
                    >
                        {formValidateStatusSearch.message}
                    </div>
                )}

            <div className="div-filters">
                {loading !== false && (
                    <div
                        className="spinner-border text-primary me-3"
                        role="status"
                    >
                        <span className="visually-hidden">Loading...</span>
                    </div>
                )}

                {loading !== true && (
                    <select
                        className="form-select me-3"
                        aria-label=".form-select-lg example"
                        value={props.FiltersApi.tag}
                        onChange={(e) => {
                            props.setFiltersApi((Filters) => ({
                                ...Filters,
                                tag: e.target.value,
                            }));
                        }}
                    >
                        <option value="">Tags</option>
                        {Tags &&
                            Tags.length > 0 &&
                            Tags.map((tag) => {
                                return (
                                    <option value={tag} key={tag}>
                                        {tag}
                                    </option>
                                );
                            })}
                    </select>
                )}

                <span className="me-3">
                    <b>Período:</b>
                </span>
                <input
                    className="form-control me-3"
                    type="date"
                    placeholder="Data Inicial"
                    disabled={props.FiltersApi.tag === "" ? "disabled" : ""}
                    onChange={(e) => {
                        props.setFiltersApi((Filters) => ({
                            ...Filters,
                            dataInicio: e.target.value,
                        }));
                    }}
                />
                <span className="me-3">até</span>
                <input
                    className="form-control me-3"
                    type="date"
                    placeholder="Data final"
                    disabled={props.FiltersApi.tag === "" ? "disabled" : ""}
                    onChange={(e) => {
                        props.setFiltersApi((Filters) => ({
                            ...Filters,
                            dataFinal: e.target.value,
                        }));
                    }}
                />

                <button
                    type="button"
                    className="btn btn-secondary"
                    disabled={props.FiltersApi.tag === "" ? "disabled" : ""}
                    onClick={() => {
                        props.setFiltersApi((Filters) => ({
                            ...Filters,
                            action: "search",
                        }));

                        handleSumit();
                    }}
                >
                    <i className="bi bi-search"></i> Pesquisar
                </button>
            </div>
        </S.FiltersCq>
    );
};

export default FiltersCq;
