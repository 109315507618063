import moment from "moment";

export const CleaningColumns = [
    {
        Header: "Tags",
        accessor: "tag",
        disableSortBy: true,
    },
    {
        Header: "Data",
        accessor: "data",
        Cell: (props) => {
            const formatedDate = moment(props.value).format("DD/MM/yyyy");
            return <span>{formatedDate}</span>;
        },
    },
    {
        Header: "Horário",
        accessor: "hora",
        disableSortBy: true,
    },
    {
        Header: "Responsável",
        accessor: "responsavel",
        disableSortBy: true,
    },
    {
        Header: "Log",
        accessor: "log",
        disableSortBy: true,
    },
    {
        Header: "Concat",
        accessor: "concat",
        disableSortBy: true,
    },
];
