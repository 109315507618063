import styled from "styled-components";

export const CqDashboardPage = styled.div`
    .main-cq-dashboard {
        margin-left: 300px;
        padding: 15px;
        overflow: hidden;
        margin-top: 70px;
        transition: margin 0.4s;
    }

    @media (width < 1368px) {
        .main-cq-dashboard {
            margin-left: 70px;
        }
    }

    @media (width < 768px) {
        .main-cq-dashboard {
            padding: 0px;
        }
    }

    @media (width < 600px) {
        .main-cq-dashboard {
            margin-left: 50px;
        }
    }
`;
