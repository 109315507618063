import React from "react";
import { Link } from "react-router-dom";
import * as S from "./styles";

const TitlesCq = (props) => {
    return (
        <S.TitlesCq className="mt-2 mb-3">
            <h4>
                <i className={props.icon}></i> {props.title}
            </h4>

            {props.backLink && (
                <Link to={props.backLink} className="btn btn-link">
                    <i className="bi bi-arrow-left-circle-fill me-2"></i>
                    Voltar
                </Link>
            )}
        </S.TitlesCq>
    );
};

export default TitlesCq;
